<template>
  <v-container fluid v-if="error">
    <v-row class="d-flex justify-end">
      <v-alert
        :value="error"
        text
        color="main_gray"
        type="info"
        width="550"
        dismissible
        @click="delete_error"
        shaped
        >{{ error_text }}
      </v-alert>
    </v-row>
  </v-container>
</template>

<script>
import store from "../store";
import { mapGetters } from "vuex";

export default {
  methods: {
    delete_error() {
      store.dispatch("project/delete_error");
    },
  },
  computed: {
    ...mapGetters({
      error: "project/error",
      error_text: "project/error_text",
    }),
  },
};
</script>
