import axios from '@/axios'


export default {
    namespaced: true,
    state: {
        token: null,
        user: null
    },
    getters: {
        authenticated(state) {
            return state.token
        },
        user(state) {
            return state.user
        },
        superuser(state) {
            return state.user.is_superuser
        },
        staff(state){
            return state.user.is_staff
        },
        permissions(state){
            return state.user ? state.user.permissions : []
        }

    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
        },

        SET_USER(state, user) {
            state.user = user

        },
    },
    actions: {
        async signIn({ dispatch }, credentials) {
            let response = await axios.post('api/auth/jwt/create/', credentials)
            return dispatch('attempt', response.data.access)
        },

        async attempt({ dispatch,commit, state }, token) {
            if (token) {
                commit('SET_TOKEN', token)
                let response = await axios.get('api/auth/users/me/')
                commit('SET_USER', response.data)
                await dispatch('project/set_constants', null, {root:true})
                dispatch('project/delete_error', null, {root:true})
            }
            if (!state.token) {
                return
            }
        },
        signOut({ commit }) {
            commit('SET_USER', null)
            commit('SET_TOKEN', null)
            commit('project/set_project', null, { root: true })
        },
    },

}