var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-app-bar',{staticClass:"main_gray",attrs:{"app":"","clipped-left":""}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ name: 'home' }}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Megapolis portal")])],1),_c('v-toolbar-title',{staticClass:"ml-4 white--text"},[_vm._v(_vm._s(_vm.project ? `/ Дом "${_vm.project.name}"` : ""))]),_c('v-spacer')],1),(_vm.authenticated)?_c('v-navigation-drawer',{staticClass:"main_gray",attrs:{"app":"","clipped":"","dark":""}},[_c('v-list',{attrs:{"nav":"","dense":"","flat":""}},[(_vm.permissions.includes('core.view_project'))?_c('v-list-item-group',{attrs:{"active-class":"custom_yellow menu_text--text"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ name: 'home' }}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-home-export-outline")])],1),_c('v-list-item-title',[_vm._v("Проекты")])],1)],1),(_vm.permissions.includes('finances.view_finances_overview'))?_c('v-list-item',{attrs:{"to":{ name: 'finances-common' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-chart-bar")])],1),_c('v-list-item-title',[_vm._v("Сводные финансы")])],1):_vm._e(),(_vm.project)?_c('div',[(_vm.permissions.includes('core.view_project'))?_c('v-list-item',{attrs:{"to":{ name: 'project' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-information-outline")])],1),_c('v-list-item-title',[_vm._v("Карточка проекта")])],1):_vm._e(),(_vm.permissions.includes('core.view_projectapartmentography') ||
          _vm.permissions.includes('core.view_apartsolutions'))?_c('v-list-item',{attrs:{"to":{ name: 'apartmentography' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-floor-plan")])],1),_c('v-list-item-title',[_vm._v("Квартирография")])],1):_vm._e(),(_vm.permissions.includes('finances.view_finances_overview') ||
                _vm.permissions.includes('finances.view_paymentregistry_out') ||
                _vm.permissions.includes('finances.view_paymentregistry_in') ||
                _vm.permissions.includes('finances.view_bugdetschedule') ||
                _vm.permissions.includes('finances.view_budgetplan'))?_c('v-list-group',{attrs:{"no-action":"","prepend-icon":"mdi-currency-rub"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Финансы")])]},proxy:true}],null,false,3463641985)},[(_vm.permissions.includes('finances.view_finances_overview'))?_c('v-list-item',{attrs:{"sub-group":"","to":{ name: 'finances' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-chart-bar")])],1),_c('v-list-item-title',[_vm._v("Сводные")])],1):_vm._e(),(_vm.permissions.includes('finances.view_paymentregistry_out'))?_c('v-list-item',{attrs:{"sub-group":"","to":{ name: 'payment-registry' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-point-of-sale")])],1),_c('v-list-item-title',[_vm._v("Реестр исх")])],1):_vm._e(),(_vm.permissions.includes('finances.view_paymentregistry_in'))?_c('v-list-item',{attrs:{"sub-group":"","to":{ name: 'payment-registry-in' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-point-of-sale")])],1),_c('v-list-item-title',[_vm._v("Реестр вх")])],1):_vm._e(),(_vm.permissions.includes('finances.view_bugdetschedule'))?_c('v-list-item',{attrs:{"sub-group":"","to":{ name: 'budget_schedule' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-progress-alert")])],1),_c('v-list-item-title',[_vm._v("График фин.")])],1):_vm._e(),(_vm.permissions.includes('finances.view_budgetplan'))?_c('v-list-item',{attrs:{"sub-group":"","to":{ name: 'budget_plan' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-progress-check")])],1),_c('v-list-item-title',[_vm._v("Бюджет")])],1):_vm._e()],1):_vm._e(),(_vm.permissions.includes('finances.view_sales_overview') ||
          _vm.permissions.includes('finances.view_salesfact') ||
          _vm.permissions.includes('finances.view_salesplan')
          )?_c('v-list-group',{attrs:{"no-action":"","prepend-icon":"mdi-cart-variant"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Продажи")])]},proxy:true}],null,false,1510060748)},[(_vm.permissions.includes('finances.view_sales_overview'))?_c('v-list-item',{attrs:{"sub-group":"","to":{ name: 'sales' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-chart-bar")])],1),_c('v-list-item-title',[_vm._v("Сводные")])],1):_vm._e(),(_vm.permissions.includes('finances.view_salesplan'))?_c('v-list-item',{attrs:{"sub-group":"","to":{ name: 'sales-plan' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-progress-alert")])],1),_c('v-list-item-title',[_vm._v("План")])],1):_vm._e(),(_vm.permissions.includes('finances.view_salesfact'))?_c('v-list-item',{attrs:{"sub-group":"","to":{ name: 'sales_fact' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-progress-check")])],1),_c('v-list-item-title',[_vm._v("Факт")])],1):_vm._e()],1):_vm._e(),(
              _vm.permissions.includes('core.view_contract') ||
              _vm.permissions.includes('core.view_contractsupplement') ||
              _vm.permissions.includes('profile.view_contractor')
            )?_c('v-list-group',{attrs:{"no-action":"","prepend-icon":"mdi-handshake-outline"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Контрагенты")])]},proxy:true}],null,false,3877876638)},[(_vm.permissions.includes('profile.view_contractor'))?_c('v-list-item',{attrs:{"sub-group":"","to":{ name: 'contractors' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-sign")])],1),_c('v-list-item-title',[_vm._v("Контрагенты")])],1):_vm._e(),(
                _vm.permissions.includes('core.view_contract') ||
                _vm.permissions.includes('core.view_contractsupplement')
              )?_c('v-list-item',{attrs:{"sub-group":"","to":{ name: 'contracts' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-sign")])],1),_c('v-list-item-title',[_vm._v("Договоры")])],1):_vm._e()],1):_vm._e()],1):_vm._e(),_c('v-list-item',{on:{"click":_vm.signOut}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout")])],1),_c('v-list-item-title',[_vm._v("Выход")])],1)],1):_vm._e()],1)],1):_vm._e(),_c('v-main',[_c('v-container',{staticClass:"my-5",attrs:{"fluid":""}},[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }