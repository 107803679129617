<template>
  <v-app>
    <v-app-bar class="main_gray" app clipped-left>
      <router-link :to="{ name: 'home' }" style="text-decoration: none">
        <v-toolbar-title class="white--text">Megapolis portal</v-toolbar-title>
      </router-link>
      <v-toolbar-title class="ml-4 white--text">{{
        project ? `/ Дом "${project.name}"` : ""
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-navigation-drawer
      class="main_gray"
      app
      v-if="authenticated"
      clipped
      dark
    >
      <v-list nav dense flat>
        <v-list-item-group
          active-class="custom_yellow menu_text--text"
          v-if="permissions.includes('core.view_project')"
        >
          <router-link :to="{ name: 'home' }" style="text-decoration: none">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-home-export-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Проекты</v-list-item-title>
            </v-list-item>
          </router-link>
          <v-list-item
              :to="{ name: 'finances-common' }"
              v-if="permissions.includes('finances.view_finances_overview')"
            >
              <v-list-item-icon>
                <v-icon>mdi-chart-bar</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Сводные финансы</v-list-item-title>
          </v-list-item>
          <div v-if="project">
            <v-list-item
              :to="{ name: 'project' }"
              v-if="permissions.includes('core.view_project')"
            >
              <v-list-item-icon>
                <v-icon>mdi-information-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Карточка проекта</v-list-item-title>
            </v-list-item>

            <v-list-item :to="{ name: 'apartmentography' }"
            v-if="permissions.includes('core.view_projectapartmentography') ||
            permissions.includes('core.view_apartsolutions')"
            >
              <v-list-item-icon>
                <v-icon>mdi-floor-plan</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Квартирография</v-list-item-title>
            </v-list-item>

            <!-- Финансы -->
            <v-list-group no-action prepend-icon="mdi-currency-rub"
            v-if="permissions.includes('finances.view_finances_overview') ||
                  permissions.includes('finances.view_paymentregistry_out') ||
                  permissions.includes('finances.view_paymentregistry_in') ||
                  permissions.includes('finances.view_bugdetschedule') ||
                  permissions.includes('finances.view_budgetplan')"
            >
              <template v-slot:activator>
                <v-list-item-title>Финансы</v-list-item-title>
              </template>

              <v-list-item
                sub-group
                :to="{ name: 'finances' }"
                v-if="permissions.includes('finances.view_finances_overview')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-chart-bar</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Сводные</v-list-item-title>
              </v-list-item>

              <v-list-item
                sub-group
                :to="{ name: 'payment-registry' }"
                v-if="permissions.includes('finances.view_paymentregistry_out')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-point-of-sale</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Реестр исх</v-list-item-title>
              </v-list-item>
              <v-list-item
                sub-group
                :to="{ name: 'payment-registry-in' }"
                v-if="permissions.includes('finances.view_paymentregistry_in')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-point-of-sale</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Реестр вх</v-list-item-title>
              </v-list-item>

              <v-list-item
                sub-group
                :to="{ name: 'budget_schedule' }"
                v-if="permissions.includes('finances.view_bugdetschedule')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-progress-alert</v-icon>
                </v-list-item-icon>
                <v-list-item-title>График фин.</v-list-item-title>
              </v-list-item>

              <v-list-item
                sub-group
                :to="{ name: 'budget_plan' }"
                v-if="permissions.includes('finances.view_budgetplan')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-progress-check</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Бюджет</v-list-item-title>
              </v-list-item>
            </v-list-group>
            <!-- /Финансы -->

            <!-- Продажи -->
            <v-list-group no-action prepend-icon="mdi-cart-variant"
            v-if="permissions.includes('finances.view_sales_overview') ||
            permissions.includes('finances.view_salesfact') ||
            permissions.includes('finances.view_salesplan')
            "
            >
              <template v-slot:activator>
                <v-list-item-title>Продажи</v-list-item-title>
              </template>
              <v-list-item sub-group :to="{ name: 'sales' }"
              v-if="permissions.includes('finances.view_sales_overview')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-chart-bar</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Сводные</v-list-item-title>
              </v-list-item>

              <v-list-item
                sub-group
                :to="{ name: 'sales-plan' }"
                v-if="permissions.includes('finances.view_salesplan')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-progress-alert</v-icon>
                </v-list-item-icon>
                <v-list-item-title>План</v-list-item-title>
              </v-list-item>

              <v-list-item
                sub-group
                :to="{ name: 'sales_fact' }"
                v-if="permissions.includes('finances.view_salesfact')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-progress-check</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Факт</v-list-item-title>
              </v-list-item>
            </v-list-group>
            <!-- /Продажи -->
            <!-- Подрядчики -->
            <v-list-group
              no-action
              prepend-icon="mdi-handshake-outline"
              v-if="
                permissions.includes('core.view_contract') ||
                permissions.includes('core.view_contractsupplement') ||
                permissions.includes('profile.view_contractor')
              "
            >
              <template v-slot:activator>
                <v-list-item-title>Контрагенты</v-list-item-title>
              </template>

              <v-list-item
                sub-group
                :to="{ name: 'contractors' }"
                v-if="permissions.includes('profile.view_contractor')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-file-sign</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Контрагенты</v-list-item-title>
              </v-list-item>

              <v-list-item
                sub-group
                :to="{ name: 'contracts' }"
                v-if="
                  permissions.includes('core.view_contract') ||
                  permissions.includes('core.view_contractsupplement')
                "
              >
                <v-list-item-icon>
                  <v-icon>mdi-file-sign</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Договоры</v-list-item-title>
              </v-list-item>
            </v-list-group>

            <!-- /Подрядчики -->

          </div>
          <v-list-item @click="signOut">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Выход</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-container class="my-5" fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "App",
  data: () => ({
    drawer: false,
  }),
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      project: "project/project",
      permissions: "auth/permissions",
    }),
  },

  methods: {
    ...mapActions({
      signOutAction: "auth/signOut",
    }),
    signOut() {
      this.signOutAction().then(() => {
        this.$router.push("/login");
      });
    },
  },
};
</script>

<style scoped>
.active_list_item {
  background: #f3d250;
}
</style>
