import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)


export default new Vuetify({
    icons: {
      iconfont: 'mdi',
    },
    theme: {
      dark: false,
      themes: {
        light: {
          primary: colors.lightBlue,
          secondary: colors.grey.darken1,
          accent: colors.pink.darken1,
          error: colors.red.accent3,
          background: colors.indigo.lighten5,
          info: colors.teal.darken1,
          custom_yellow: "#F3D250",
          menu_text: "#474040",
          main_gray: "#2B2A29"
        },
        dark: {
          primary: colors.blue.darken4,
          background: colors.indigo.base,
          info: colors.teal.lighten1,
          custom_yellow: "#F3D250",
          menu_text: "#474040"
        },
      },
    },
  });
