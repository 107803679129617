import axios from "axios";
import store from "../store";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE
    ? process.env.VUE_APP_API_BASE
    : "http://localhost:8080",
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      store.dispatch("auth/signOut");
    }
    const contentType = error.response.headers["content-type"];
    let error_text;
    if (contentType?.includes("application/json")) {
      error_text = error.response.data;
    } else {
      error_text = "Упс, на сервере что-то пошло не так"
    }
    store.dispatch("project/set_error", error_text);
    window.scrollTo(0, 0);
    return Promise.reject(error);
  }
);

export default instance;
