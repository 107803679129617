import axios from '@/axios'


export default {
  namespaced: true,
  state: {
    project: null,
    error: false,
    error_text: null,
    constants: []
  },
  getters: {
    project(state) {
      return state.project;
    },
    error(state) {
      return state.error;
    },
    error_text(state) {
        return state.error_text
    },
    constants(state) {
      return state.constants
    }
  },
  mutations: {
    set_project(state, data) {
      state.project = data
    },
    set_error(state, data) {
      state.error = true
      state.error_text = data
    },
    detele_error(state, data) {
      state.error = false
      state.error_text = null
    },
    set_constants(state, data) {
      state.constants = data
    }
  },
  actions: {
    set_project_action({ commit }, data) {
      commit("set_project", data);
    },
    set_error({ commit }, data) {
      commit("set_error", data);
    },
    delete_error({ commit }) {
      commit("detele_error");
    },
    async set_constants({ commit }) {
      let response = await axios.get('api/constants/')
      commit("set_constants", response.data);
    },
  },
};
