import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Projects.vue'),
    meta:{
      requiresAuth: true
    },
  },
  {
    path: '/project/',
    name: 'project',
    component: () => import('../views/ProjectDetail.vue'),
    meta:{
      requiresAuth: true
    },
  },
  {
    path: '/contractors-list/',
    name: 'contractors-list',
    component: () => import('../views/ContractorsList.vue'),
    meta:{
      requiresAuth: true
    },
  },
  {
    path: '/finances/',
    name: 'finances',
    component: () => import('../views/Finances.vue'),
    meta:{
      requiresAuth: true
    },
  },
  {
    path: '/finances-common/',
    name: 'finances-common',
    component: () => import('../views/FinancesCommon.vue'),
    meta:{
      requiresAuth: true
    },
  },
  {
    path: '/sales/',
    name: 'sales',
    component: () => import('../views/Sales.vue'),
    meta:{
      requiresAuth: true
    },
  },
  {
    path: '/sales-plan/',
    name: 'sales-plan',
    component: () => import('../views/SalesPlan.vue'),
    meta:{
      requiresAuth: true
    },
  },
  {
    path: '/new-project/',
    name: 'new-project',
    component: () => import('../views/NewProject.vue'),
    meta:{
      requiresAuth: true
    },
  },
  {
    path: '/edit-project/:project_id',
    name: 'edit-project',
    component: () => import('../views/EditProject.vue'),
    meta:{
      requiresAuth: true
    },
  },
  {
    path: '/payment-registry/',
    name: 'payment-registry',
    component: () => import('../views/PaymentRegistry.vue'),
    meta:{
      requiresAuth: true
    },
  },
  {
    path: '/payment-registry-in/',
    name: 'payment-registry-in',
    component: () => import('../views/PaymentRegistryIn.vue'),
    meta:{
      requiresAuth: true
    },
  },
  {
    path: '/contractors/',
    name: 'contractors',
    component: () => import('../views/Contractors.vue'),
    meta:{
      requiresAuth: true
    },
  },
  {
    path: '/contracts/',
    name: 'contracts',
    component: () => import('../views/Contracts.vue'),
    meta:{
      requiresAuth: true
    },
  },
  {
    path: '/apartmentography/',
    name: 'apartmentography',
    component: () => import('../views/Apartmentography.vue'),
    meta:{
      requiresAuth: true
    },
  },
  {
    path: '/banks/',
    name: 'banks',
    component: () => import('../views/Banks.vue'),
    meta:{
      requiresAuth: true
    },
  },
  {
    path: '/budget_schedule/',
    name: 'budget_schedule',
    component: () => import('../views/BudgetSchedule.vue'),
    meta:{
      requiresAuth: true
    },
  },
  {
    path: '/budget_plan/',
    name: 'budget_plan',
    component: () => import('../views/BudgetPlan.vue'),
    meta:{
      requiresAuth: true
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import('../views/Registration.vue')
  },
  {
    path: '/password_reset_confirm/:uid/:token',
    name: 'password_reset_confirm',
    component: () => import('../views/PasswordResetConfirm.vue'),
  },
  {
    path: '/password_reset',
    name: 'password_reset',
    component: () => import('../views/PasswordReset.vue'),
  },
  {
    path: '/sales_fact',
    name: 'sales_fact',
    component: () => import('../views/SalesFactSplitted.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const authenticatedUser = store.getters['auth/authenticated'];
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && ! authenticatedUser) {
    next('/login')
  } else next();
});


export default router
